<template>
	<layout class="referral">
		<c-gears wrapper="2" class="referral__gears bg-matins white text-center" gear-color="midnight">
			<div v-show="orgReqPending" class="text-center">
				<span class="pin-y-center pin-x-center pt">
					<inline svg="cog" class="white spin sz-9" />
				</span>
			</div>
			<div :class="{'opacity-0': orgReqPending}">
				<h1 class="white sz-9"><span v-if="organization">{{ organization }} Thinks </span>You'll Love&nbsp;Us</h1>
				<p v-if="organization" class="lede">With an intuitive builder and unbeatable pricing, they're probably&nbsp;right.</p>
				<p v-else class="lede">
					With advanced features, unlimited forms, and unbeatable pricing, find out why Cognito Forms is a better form&nbsp;builder.
				</p>
			</div>
			<a href="#pricing" class="button button--primary sz-6">
				Start My Free Trial
			</a>
		</c-gears>

		<section class="bg-puff matins py-3">
			<div class="wrapper wrapper--2 flex gutters flex--align">
				<inline svg="complex-icons/free" alt="Free!" class="w-4" />
				<p class="pl-0 sz-4 b3:sz-5">Experience any of our plans free for 30&nbsp;days.</p>
			</div>
		</section>

		<section id="features-pricing" class="bg-white py-5 my-3 feature-matrix">
			<div class="wrapper">
				<h2 id="pricing" class="gilroy heavy sz-7 matins mb-5 text-center">Features &amp; Pricing</h2>
				<div class="referral-cards b6:flex flex--even mt-2 gutters">
					<div class="referral__standard-features b9:show">
						<div class="referral__standard-features-intro">
							<h3 class="sz-5 ansel">Standard Features</h3>
							<p class="sz-2">
								All plans include <span class="b10:show">unlimited forms and fields, calculations,</span> conditional logic,
								email notifications, <span class="b10:show">repeating sections,</span> and <a href="#standard-features">much more</a>.
							</p>
						</div>

						<h3 class="referral__advanced-features-header sz-5 ansel mt-0">Advanced Features</h3>
						<table class="referral__table table--no-style">
							<tbody>
								<tr v-for="(feature, i) in additionalFeatures.filter(f => isFeatureVisible(f.name, f.featureFlag))" :key="i">
									<th>
										<span class="mr-1">{{ feature.displayName || feature.name }}</span>
										<c-tool-tip v-if="feature.description" placement="top-start" :visible-arrow="false" show-on-click>
											<template #button>
												<inline svg="info" />
											</template>
											<h3>{{ feature.displayName || feature.name }}</h3>
											<div class="flex gutters">
												<p class="w-66">{{ feature.description }}</p>
												<div class="w-33">
													<inline v-if="feature.illustration" :svg="`complex-icons/${feature.illustration}`" />
												</div>
											</div>
										</c-tool-tip>
									</th>
									<td v-for="(plan, j) in ['Pro', 'Team', 'Enterprise']" :key="j">
										<template v-if="['Entries', 'Users', 'Storage'].includes(feature.name)">
											<template v-if="feature.name === 'Entries'">
												{{ plan === 'Enterprise' ? 'Unlimited' : pricingTier[plan].EntryLimit | number }}
											</template>
											<template v-else>
												{{ feature.name === 'Users' ? pricingTier[plan].UserLimit : pricingTier[plan].StorageLimitText }}
											</template>
										</template>
										<template v-else>
											<template v-if="isMonthlyPaidFeature(feature.name)">{{ getMonthlyPrice(feature.name, plan.toLowerCase()) }}</template>
											<inline v-else-if="isFeatureBoolean(feature.name, plan)" :svg="isFeatureInPlan(feature.name, plan.toLowerCase()) ? 'check' : 'ex'" />
										</template>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<referral-plan-card v-for="(plan, i) in ['Pro', 'Team', 'Enterprise']" :key="i" :plan-name="plan" :monthly-amount="pricingTier[plan].MonthlyAmount + ''">
						<dl class="referral-card no-list-style mt px b9:hide flex flex--wrap">
							<template v-for="(feature, j) in additionalFeatures.filter(f => isFeatureVisible(f.name, f.featureFlag))">
								<dt
									:key="'dt'+j"
									:class="isFeatureBoolean(feature.name, plan) ? 'referral-card__boolean w-75' : 'referral-card__text'"
								>
									<c-tool-tip v-if="feature.description" placement="top-start">
										<template #button>
											<span class="referral-card__dl-underline">{{ feature.name }}</span>
										</template>
										<h3>{{ feature.displayName || feature.name }}</h3>
										<div class="flex gutters">
											<p class="w-66">{{ feature.description }}</p>
											<div class="w-33">
												<inline v-if="feature.illustration" :svg="`complex-icons/${feature.illustration}`" />
											</div>
										</div>
									</c-tool-tip>
									<template v-else>
										{{ feature.name }}
									</template>
								</dt>
								<dd
									:key="'dd'+j"
									:class="isFeatureBoolean(feature.name, plan) ? 'referral-card__boolean w-25' : 'referral-card__text'"
								>
									<template v-if="['Entries', 'Users', 'Storage'].includes(feature.name)">
										<template v-if="feature.name === 'Entries'">
											{{ plan === 'Enterprise' ? 'Unlimited' : pricingTier[plan].EntryLimit | number }}
										</template>
										<template v-else>
											{{ feature.name === 'Users' ? pricingTier[plan].UserLimit : pricingTier[plan].StorageLimitText }}
										</template>
									</template>
									<template v-else>
										<template v-if="isMonthlyPaidFeature(feature.name)">{{ getMonthlyPrice(feature.name, plan.toLowerCase()) }}</template>
										<inline v-else-if="isFeatureBoolean(feature.name, plan)" :svg="isFeatureInPlan(feature.name, plan.toLowerCase()) ? 'check' : 'ex'" />
									</template>
								</dd>
							</template>
						</dl>
					</referral-plan-card>
				</div>
			</div>
		</section>

		<section id="standard-features" class="referral-card wrapper py-5 mb-6">
			<h2 class="gilroy heavy sz-7 ansel">Standard Features</h2>
			<div class="b6:flex gutters b6:flex--2-cols b5-b10:flex--2-cols">
				<div class="b6:w-66">
					<ul class="unwrap b5:flex b5:flex--2-cols">
						<li>
							<c-feature-link
								title="Calculations"
								illustration="calculations"
							>
								Let your forms do the math for you&mdash;build powerful calculations that can total costs, compare dates and so much more.
							</c-feature-link>
						</li>
						<li>
							<c-feature-link
								title="Responsive"
								illustration="responsive-forms"
							>
								Any mobile phone, any tablet, any computer&mdash;build it once and your Cognito Form will look awesome on all of them.
							</c-feature-link>
						</li>
						<li>
							<c-feature-link
								title="File Uploads"
								illustration="file-uploads"
							>
								Capturing multiple files at once, setting file size and type limits&mdash;it all comes free with every single Cognito Forms account.
							</c-feature-link>
						</li>
						<li>
							<c-feature-link
								title="Email Notifications"
								illustration="email-notifications"
							>
								Create custom autoresponder emails that include all the details of your customer&rsquo;s purchase or entry.
							</c-feature-link>
						</li>
						<li>
							<c-feature-link
								title="Quantity Limits"
								illustration="quantity-limits"
							>
								Set limits and track quantities for product order forms, signup forms, and more.
							</c-feature-link>
						</li>
						<li>
							<c-feature-link
								title="Spam Prevention"
								illustration="spam-prevention"
							>
								Keep your forms easy-to-use while eliminating rogue entries with our automatic spam prevention.
							</c-feature-link>
						</li>
						<li>
							<c-feature-link
								title="Repeating Sections &amp; Tables"
								illustration="repeating-sections-tables"
							>
								Collect as much or as little data as your customers can give, without any extra work or adding a lot of clutter.
							</c-feature-link>
						</li>
						<li>
							<c-feature-link
								title="Conditional Logic"
								illustration="conditional-logic"
							>
								Control what fields your users see and when they see them with our easy-to-use conditional logic builder.
							</c-feature-link>
						</li>
						<li>
							<c-feature-link
								title="Unlimited Forms"
								illustration="unlimited-forms-fields"
							>
								Collect all the data you need, no matter what&mdash;make as many forms as you want, with as many fields as you want.
							</c-feature-link>
						</li>
						<li>
							<c-feature-link
								title="WordPress Plugin"
							>
								<template #illustration>
									<img :src="require('@cognitoforms/shared-components/src/assets/graphics/images/wordpress.svg')" alt="Wordpress" />
								</template>
								Build and publish your forms in just minutes without ever leaving WordPress. No coding, just a quick and easy install.
							</c-feature-link>
						</li>
					</ul>
				</div>
				<div class="b6:w-33 text-center">
					<div class="bg-arnold pb referral-card--free">
						<h3 class="bg-lange sz-4 p-2">Individual Plan</h3>
						<h4 class="matins uppercase sz-8 m-0">Free</h4>
						<p class="italic sz-2 m-0 mb-2">Forever</p>
						<router-link :to="getIndividualPlanLink()" class="button button--primary bg-jaywalk sz-3">
							Sign Up
						</router-link>
						<p class="sz-2">Includes all standard features</p>
						<dl class="px flex flex--wrap">
							<dt class="referral-card__text">Entries</dt>
							<dd class="referral-card__text">{{ pricingTier.Individual.EntryLimit }}/month</dd>

							<dt class="referral-card__text">Users</dt>
							<dd class="referral-card__text">{{ pricingTier.Individual.UserLimit }} user</dd>

							<dt class="referral-card__text">Storage</dt>
							<dd class="referral-card__text">{{ pricingTier.Individual.StorageLimitText }}</dd>
						</dl>
					</div>
				</div>
			</div>
		</section>
	</layout>
</template>

<script>
	import CToolTip from '@cognitoforms/shared-components/src/components/interaction/ToolTip.vue';
	import Layout from 'src/layouts/PublicLayout.vue';
	import CGears from 'src/components/website/Gears.vue';
	import CFeatureLink from 'src/components/content/FeatureLink.vue';
	import ReferralPlanCard from '../pricing/ReferralPlanCard.vue';
	import pricingTierMixin from '@cognitoforms/shared-components/src/mixins/pricingTier';
	import { getReferringOrganizationName } from '@cognitoforms/api/services/referral.ts';
	import { isPrerender, capitalize } from '@cognitoforms/utils/utilities';

	export default {
		components: {
			CToolTip,
			Layout,
			CGears,
			CFeatureLink,
			ReferralPlanCard
		},
		mixins: [pricingTierMixin],
		inject: ['siteManifest'],
		beforeRouteEnter(to, from, next) {
			if (isPrerender()) {
				next();
			}
			else {
				to.params.crs = (to.query ? to.query.crs : null) || '';
				next();
			}
		},
		inheritAttrs: false,
		props: {
			crs: { type: String, default: '' }
		},
		data() {
			return {
				organization: '',
				orgReqPending: true,
				title: 'You\'ll Love Us',
				description: 'With advanced features, unlimited forms, and unbeatable pricing, find out why Cognito Forms is the best form builder.',
				canonicalPath: 'l/referral',
				plans: [
					'individual',
					'pro',
					'team',
					'enterprise'
				],
				additionalFeatures: [
					{ name: 'Entries' },
					{ name: 'Users' },
					{ name: 'Additional Users' },
					{ name: 'Storage' },
					{
						name: 'Document Generation',
						displayName: 'Custom Document Templates',
						illustration: 'document-generation',
						discription: 'Create PDF and Word docs from your entry data, completely customized the way you want.'
					},
					{
						name: 'Electronic Signatures',
						illustration: 'electronic-signatures',
						description: 'Go paperless and collect signatures on every form and on any device.'
					},
					{
						name: 'Multiple Email Notifications'
					},
					{
						name: 'Workflow Links',
						illustration: 'workflow',
						description: 'Build flexible workflows by sharing role-based links to your entries.'
					},
					{
						name: 'Multi-Page Forms & Page Breaks',
						displayName: 'Multi-page Forms',
						illustration: 'multi-page-forms',
						description: 'Get more responses to your complex surveys and long forms with page breaks, progress bars and conditional pages.'
					},
					{
						name: 'Save & Resume',
						illustration: 'save-resume',
						description: 'Allow users to pick up right where they left off by saving their progress on partially completed form responses.'
					},
					{
						name: 'Removable Cognito Forms Branding',
						displayName: 'Removable Branding'
					},
					{
						name: 'Email Attachments',
						displayName: 'File Uploads Attached to Email Notifications'
					},
					{
						name: 'Bulk File Downloads'
					},
					{
						name: 'Online Payment',
						displayName: 'Payment Processing',
						illustration: 'payment',
						description: 'Accept secure credit card payments with PayPal, Stripe or Square.'
					},
					{
						name: 'Integrations'
					},
					{
						name: 'Data Encryption',
						illustration: 'data-encryption',
						description: 'Keep your form data safe and sound when you enable entry data encryption and protect fields that contain sensitive information.'
					},
					{
						name: 'HIPAA Compliance',
						illustration: 'hipaa-compliance',
						description: 'Build HIPAA-compliant forms for new patient registration, appointment scheduling, refill requests, patient satisfaction surveys and even online bill payment.'
					},
					{
						name: 'Require Two-Factor Authentication'
					}
				]

			};
		},
		head() {
			return {
				title: this.title,
				meta: [
					{ property: 'og:title', content: this.title, id: 'og:title' },
					{ property: 'twitter:title', content: this.title, id: 'twitter:title' },
					{ name: 'description', content: this.description, id: 'desc' },
					{ property: 'og:description', content: this.description, id: 'og:description' },
					{ property: 'twitter:description', content: this.description, id: 'twitter:description' }
				],
				link: [
					{ rel: 'canonical', href: 'https://www.cognitoforms.com/' + this.canonicalPath, id: 'canonical' }
				]
			};
		},
		mounted() {
			this.orgReqPending = true;
			getReferringOrganizationName(this.crs).then(response => {
				this.organization = response.Name;
				this.orgReqPending = false;
			}).catch(() => {
				this.orgReqPending = false;
			});
		},
		methods: {
			getIndividualPlanLink() {
				return '/signup?source=tryitnow&returnUrl=' + encodeURIComponent('/forms?plan=' + this.pricingTier.Individual.Product.DisplayName);
			},
			getFeature(name) {
				const feature = this.siteManifest.product.list.all.filter(feature => feature.title === name);
				return feature[0];
			},
			isFeatureVisible(featureTitle) {
				if (['Entries', 'Users', 'Storage'].includes(featureTitle))
					return true;

				// Make sure the feature is valid / exists in the manifest
				const feature = this.getFeature(featureTitle);
				if (!feature) {
					if (process.env.NODE_ENV === 'development')
						console.warn(`Feature ${featureTitle} was not found.`);
					return false;
				}

				// Make sure the feature is tied to plan level (or has plan-specific limits)
				if (!feature.plan) {
					if (process.env.NODE_ENV === 'development')
						console.warn(`Feature ${featureTitle} is not restricted to a particular plan level.`);
					return false;
				}

				return true;
			},
			getMonthlyPrice(featureTitle, plan) {
				const feature = this.getFeature(featureTitle);
				if (!feature)
					return null;

				// If it's not available on a certain plan, it's listed as `null` in the plan array
				let pricePerMonth = feature.plan[this.plans.indexOf(plan)];
				if (!pricePerMonth)
					return '';

				// Get the plan data from the pricing tier if available
				const planName = capitalize(plan);
				const planData = this.pricingTier ? this.pricingTier[planName] : null;
				if (!planData)
					return '';

				// Use the value from the manifest as the template, which will be injected with data from the pricing from the product
				const pricePerMonthTemplate = pricePerMonth;

				// Get the price per month from the dynamic pricing tier data
				const pricePerMonthPropertyName = feature.title === 'Additional Users' ? 'AdditionalUserFee' : feature.title === 'Document Generation' ? 'AdditionalTemplateFee' : null;
				pricePerMonth = planData[pricePerMonthPropertyName];

				// Price per month regex, ex: $X/y/z
				const pricePerMonthRegex = /^\$(\d+)\/(.*)\/(.*)$/g;
				const pricePerMonthComponents = pricePerMonthRegex.exec(pricePerMonthTemplate);
				if (pricePerMonthComponents && typeof pricePerMonth === 'number') {
					const unit = pricePerMonthComponents[2];
					const interval = pricePerMonthComponents[3];
					pricePerMonth = `$${pricePerMonth}/${unit}/${interval}`;
				}
				else {
					// Get the price per month from the dynamic pricing tier data
					const limitPerMonthPropertyName = feature.title === 'Additional Users' ? 'UserLimit' : feature.title === 'Document Generation' ? 'CustomTemplateLimit' : null;
					const limitPerMonth = planData[limitPerMonthPropertyName];

					// Price per month regex with included amount, ex: N y free + $X/y/z
					const pricePerMonthWithIncludedRegex = /^(\d+)((.*)free)(\s+\+\s+)\$(\d+)\/(.*)\/(.*)$/g;
					const pricePerMonthWithIncludedComponents = pricePerMonthWithIncludedRegex.exec(pricePerMonthTemplate);
					if (pricePerMonthWithIncludedComponents && typeof pricePerMonth === 'number' && typeof limitPerMonth === 'number') {
						const includedSuffix = pricePerMonthWithIncludedComponents[2];
						const separator = pricePerMonthWithIncludedComponents[4];
						const unit = pricePerMonthWithIncludedComponents[6];
						const interval = pricePerMonthWithIncludedComponents[7];
						pricePerMonth = `${limitPerMonth}${includedSuffix}${separator}$${pricePerMonth}/${unit}/${interval}`;
					}
				}

				return pricePerMonth;
			},
			isFeatureInPlan(featureTitle, plan) {
				const feature = this.getFeature(featureTitle);

				if (!feature)
					return null;

				if (this.isMonthlyPaidFeature(featureTitle))
					return null;

				if (feature.plan === plan)
					return true;

				// If the current plan column is not the feature's first plan available, then check if it has already passed
				// ex. if enterprise is passed in and the feature's plan is pro, pro is plans[1], enterprise is plans[3],
				// so output true for enterprise column
				// (0) individual -> (1) pro -> (2) team -> (3) enterprise
				let featurePlanLevel;
				// current column in the table.
				let currentPlanLevel;

				for (let i = 0; i < this.plans.length; i++) {
					if (plan === this.plans[i])
						currentPlanLevel = i;

					if (feature.plan === this.plans[i])
						featurePlanLevel = i;
				}

				return (featurePlanLevel < currentPlanLevel);
			},
			isMonthlyPaidFeature(featureTitle) {
				return featureTitle === 'Additional Users' || featureTitle === 'Document Generation';
			},
			isFeatureBoolean(featureTitle, plan) {
				return typeof this.isFeatureInPlan(featureTitle, plan.toLowerCase()) === 'boolean';
			}
		}
	};
</script>

<style lang="scss" src="@cognitoforms/styles/website/feature-list.scss"></style>

<style lang="scss">
// 😜 = Shenanigans to keep the table about the same height as the plan cards.
// Using JS to keep these heights in sync would, I'm pretty sure, end up not working on prerendered version (which is by far the most important).

.referral {

	&__gears.referral__gears {
		padding-top: calc(100px + 3rem);
	}

	&__gears {

		.i-loading {
			width: $size__icon--xl;
		}
	}

	$card-margins: $gutter*.75;

	&__standard-features {
		position: static;
	}

	&__standard-features-intro { // 😜
		min-height: 140px;
	}

	&__advanced-features-header.referral__advanced-features-header { // 😜
		@include not-ie {
			display: flex;
			align-items: flex-end;
			min-height: 80px;
		}

		@include ie {
			margin-top: 45px;
		}
	}

	&__table {
		position: absolute;
		width: 100%;
		max-width: $max-width;
		margin-left: -$gutter;
		border-collapse: collapse;

		td,
		th {
			position: relative;
			width: 25%;
			border-right: $gutter transparent solid;
			border-left: $gutter transparent solid;
			text-align: center;
		}

		td {
			padding: math.div($gutter, 2) $card-margins;
		}

		th {
			padding: math.div($gutter, 2) 0;
			text-align: left;
		}

		@include b-down(1000px) {

			td,
			th {
				font-size: 1.65vw;// 😜
			}
		}

		tr:not(:last-child) th::after,
		tr:not(:last-child) td::after {
			display: block;
			position: absolute;
			bottom: 0;
			height: 1px;
			content: '';
		}

		td::after {
			left: $card-margins;
			right: $card-margins;
			background: $brady;
		}

		th::after {
			left: 0;
			right: 0;
			background: $air;
		}

		tr:hover td {
			background: rgba($deep, .05);
		}

		tr:hover th {
			color: $deep;
		}

		.i-info {
			width: 1.5em;
			color: $casablanca;
		}
	}
}

.referral-card {
	@include b-up($b9) {
		min-height: 1400px;// 😜
	}

	dt {
		display: flex;
		align-items: center;
		font-size: modular-scale(2);
		text-align: left;
	}

	dd {
		text-align: right;
	}

	&__boolean {
		margin: 0;
		padding: math.div($gutter, 4) 0;
	}

	&__text {
		width: 100%;
		margin: 0;
	}

	&--free {

		.referral-card__text {
			width: 50%;
			margin: 0;
			padding-top: 10px;
			padding-bottom: 10px;
		}

		dd:not(:last-of-type),
		dt:not(:last-of-type) {
			border-bottom: 1px solid $mapplethorpe;
		}
	}

	@include not-ie {

		.i-check,
		.i-ex {
			height: 100%;
		}
	}

	&__dl-underline {
		border-bottom: dashed 1px $brady;
	}
}

dt.referral-card__text {
	margin-bottom: math.div($gutter, 8);
	padding-top: math.div($gutter, 4);
}

dd.referral-card__text {
	padding-bottom: math.div($gutter, 4);
	font-size: modular-scale(2);
	font-weight: $heavy;
}

dd:not(:last-of-type).referral-card__text,
.referral-card__boolean:not(:last-of-type) {
	border-bottom: 1px solid $mapplethorpe;
}
</style>